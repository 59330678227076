import React, { useState } 			from 'react';
import { sendPasswordResetEmail } 	from 'firebase/auth';
import { auth } 					from '../utils/firebase';



const PasswordReset = () => {

	const [email, setEmail] = useState('');
	const [message, setMessage] = useState('');
	const [error, setError] = useState('');



	const handlePasswordReset = async (e) => {

		e.preventDefault();
		setMessage('');
		setError('');

		try {
		
			await sendPasswordResetEmail(auth, email);
			setMessage('An mail with your password has been sent');
		} 
		catch (error) {
		
			setError('Something went wrong. Please check your email address and try again');
			console.error(error);
		}
	};



	return (
		<div 
			className		="form-container"
		>
			<h2>Reset password</h2>
		
			<form 
				onSubmit		= {handlePasswordReset}
			>
				<input
					className		= "text-input"
					type			= "email"
					placeholder		= "Enter your email address"
					value			= {email}
					onChange		= {(e) => setEmail(e.target.value)}
				/>

				<button 
					className		= "form-button" 
					type			= "submit"
				>
					Reset password
				</button>
			</form>

			{
				message && 
				<p>
					{message}
				</p>
			}
			
			{
				error && 
				<p 
					style			= {{ color: 'red' }}
				>
					{error}
				</p>}
		</div>
	);
};

export default PasswordReset;
