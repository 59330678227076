import 
	React, 
	{ 
		useEffect, 
		useState 
	} 								from 'react';

import { 
	GoogleAuthProvider, 
	signInWithPopup,
	getAuth,
	signInWithEmailAndPassword,
	setPersistence, 
	browserLocalPersistence
}									from 'firebase/auth';

import { useAuthState } 			from "react-firebase-hooks/auth";
import { auth } 					from '../utils/firebase';
import {
	useLocation,
	useNavigate
} 									from 'react-router-dom';


function Success() {

	const navigate 							= useNavigate();



	return (
		<div>
			<h1>Thank you for your purchase!</h1>

			<p 
				
				onClick = { ()=> {navigate( "/mycourses")}}

				style = {{
							color:					'var(--tertiary-color)',
							cursor: 				'pointer',
			
							fontSize: 				'24px',
							marginLeft:				'8px',
							marginTop: 				'4rem',

							// fontFamily: 		"Roboto, sans-serif"
						}}
				
			>
				Go to my courses
			</p>
		</div>
	)
}

export default Success;