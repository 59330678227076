import {
	GoogleAuthProvider,
	signInWithPopup,
	getAuth, 
	createUserWithEmailAndPassword
} from "firebase/auth";
import { auth } 					from '../utils/firebase';

import React, {useState} from 'react';
import {
	useLocation,
	useNavigate,
}  									from 'react-router-dom';

import { FcGoogle } 				from "react-icons/fc";



export default function SignUp() {

	const navigate 							= useNavigate();
	const location 							= useLocation();
	const state 							= location.state || {};


	const [
		credentials, 
		setCredentials
	] 										= useState({email: "", password: ""});
	const [errorMessage, setErrorMessage] 	= useState('');

	const googleProvider 					= new GoogleAuthProvider();



	const signUp							= async ()=>{

												const auth 				= getAuth();

												try {
													const userCredential 	= await createUserWithEmailAndPassword(auth, credentials.email, credentials.password);
													
													// Signed up 
													const user 				= userCredential.user;

													// In case of pending order:
													if( state.retryOrderConfig ){												

														navigate(
															'/product/'+state.retryOrderConfig.courseId, 
															{ 
																state: { 
																	course: 			state.retryOrderConfig.course,
																	retryOrderConfig:	state.retryOrderConfig
																} });

														return;
													}
												}
												catch (error) {
													// Controleer de foutcode en toon een passende foutmelding
													switch (error.code) {
														case 'auth/email-already-in-use':
															setErrorMessage('This email is already in use. Please try to login.');
															break;
														case 'auth/weak-password':
															setErrorMessage('Weak password, choose a stronger password');
															break;
														case 'auth/invalid-email':
															setErrorMessage('This is not a valid email address');
															break;
														case 'auth/operation-not-allowed':
															setErrorMessage('Registration is currently disabled, please contact our support');
															break;
														default:
															setErrorMessage('Something went wrong, please try again');
															break;
													}
												}
											};

	

	const GoogleLogin 						= async () => {
												try {
													const result = await signInWithPopup(auth,googleProvider);

													// In case of pending order:
													if( state.retryOrderConfig ){

														navigate(
															'/product/'+state.retryOrderConfig.courseId, 
															{ 
																state: { 
																	course: 			state.retryOrderConfig.course,
																	retryOrderConfig:	state.retryOrderConfig
																} });

														return;
													}

													navigate('/');
												}
												catch(error){

													// Foutmelding afhandelen
													if (error.code === 'auth/email-already-in-use') {
														setErrorMessage('This email is already in use');
													} else {
														setErrorMessage('Something went wrong please try another way');
													}

													console.log(error);
												}
											}



  	return (

		<div>
			<h3 
				style={{
					marginTop:				'10vh'
				}}
			>
				{/* Login */}
			</h3>

			
			<div
			
				style={{ 
					
					marginTop:					'2rem',

					display: 					'flex',
					gap: 						'2rem',
					flexDirection:				'column',
					alignItems: 				'center',			// verticale as
				}}
			>
				
				<div className				="form-container">
				
					<input

						className				="text-input"
						value					={credentials.email}
						type					="text"
						placeholder				='Please enter your e-mail address'
						onChange				={event => setCredentials({email: event.target.value, password: credentials.password})}
					/>
					<input
						className				="text-input"
						value					={credentials.password}
						type					="password"
						placeholder				='enter your password'
						onChange				={event => setCredentials({email: credentials.email, password: event.target.value})}
					/>
					<button
						className				={`form-button ${!(/^([A-Za-z0-9_\-.])+@([A-Za-z0-9_\-.])+\.([A-Za-z]{2,})$/.test(credentials.email)) ? 'disabled' : ''}`}
						disabled				={ !(/^([A-Za-z0-9_\-.])+@([A-Za-z0-9_\-.])+\.([A-Za-z]{2,})$/.test(credentials.email)) }
						onClick					={()=>{
													signUp();
												}}
					>
						sign up
					</button>

					<div
						style={{ 
							
							marginTop:			'1rem',
							color:				'red'
						}}
					>
						{errorMessage && <p className="error-message">{errorMessage}</p>}
					</div>
				</div>
			</div>



			<div className="divider-container">
				<div className="line"></div>
				<p>or</p>
				<div className="line"></div>
			</div>



			<div
				style						={{ 
												display: 					'flex',
												alignItems: 				'flex-start',		// verticale as
												justifyContent:				'space-around',		// horizontale as
												padding:					20,
												color:						'var(--secondary-color)'
											}}
			>

				<button 
					style						= {{ 
													display: 					'flex',
													justifyContent:				'space-between',

													height: 					'50px',
													lineHeight: 				'50px', 
													boxSizing: 					'border-box',
													paddingBottom:				20,
													paddingLeft:				12,
													paddingRight:				16,

													color:						'var(--secondary-color)',
													backgroundColor:			'var(--primary-color)',

													border:						'2px solid var(--secondary-color)',
													borderRadius: 				'8px',
													fontSize: 					'1rem',
													cursor: 					'pointer'
												}}

					onClick						= {GoogleLogin}
				>

					<div
						style						={{
														padding:				2,
														paddingRight:			8,
													
													}}
					>
						<FcGoogle />
					</div>
					<div>
						Sign in with Google
					</div>
				</button>
			</div>

			{/* 
			
				[ ] errorMessage is nu alleen voor signup met email en wachtwoord, is die hier ook nodig? (jan 25)

			<div
				style={{ 
					marginTop:			'1rem',
					color:				'red'
				}}
			>
				{errorMessage && <p className="error-message">{errorMessage}</p>}
			</div> */}

		</div>
	)
}
