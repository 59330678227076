import 
	React, 
	{ useState } 					from 'react';

import 
{
	useLocation,
	useNavigate 
} 									from 'react-router-dom';

import 
{ 
	getFunctions, 
	httpsCallable 
} 									from "firebase/functions";

import { app, auth } 				from '../utils/firebase';
import { useAuthState } 			from "react-firebase-hooks/auth";

import { ClipLoader } 				from 'react-spinners';
import VideoPlayer 					from '../components/VideoPlayer';



export default function ProductDetail() {
	
	const functions 						= getFunctions(app, "us-central1");
	const [user, loading] 					= useAuthState(auth);

	const [checkoutLoading, setCheckoutLoading] 	= useState(false);

	const location 							= useLocation();
	const navigate 							= useNavigate();
	
	/*** 
		- we gebruiken state om de juiste course te bepalen
		- en voor een eventuele redirect na login/signup
	*/
	const state 							= location.state || {};
	const course							= state.course;
	const retryOrderConfig					= state.retryOrderConfig;

	const currentPriceIndex					= retryOrderConfig?.priceIndex	|| 0;

	const [selectedPriceIndex, setSelectedPriceIndex] = useState(currentPriceIndex);



	const buyProduct = async (priceIndex) => {

		priceIndex 					= priceIndex || selectedPriceIndex;

		const price					= course.prices[priceIndex];
		const priceId				= course.priceIds[priceIndex];
		const currency				= price[0];


		// Check of gebruiker al een account heeft
		if( ! user ){

			navigate(

				`/login`,

				/***
					object toevoegen voor afmaken bestelling 
				*/
				{ state: { 
					retryOrderConfig: { 
						courseId:		course.id,
						priceIndex: 	priceIndex,
						priceId: 		priceId,
						course: 		course
					} 
				} }
			);

			return;
		}


		let paymentMethodTypes		= ['card'];
		if( currency == '$' ){
			// ..
		}
		else if( currency == '€' ){
			paymentMethodTypes.push( 'ideal' );
		}

		// Door naar Stripe checkout
		try {

			setCheckoutLoading( true );

			const checkout 						= httpsCallable( functions, 'checkout' );

			checkout( 
				{
					items: 						[
													{
														"id": 			priceId,
														"quantity": 	1
													}
												],
					paymentMethodTypes:			paymentMethodTypes
				}
			)
			.then((response) => {

				setCheckoutLoading( false );

				return JSON.parse(response.data);
			})
			.then((response) => {
			
				if(response.url) {
					window.location.assign(response.url); // Forwarding user to Stripe
				}
			});
		}
		catch(error){
			console.log(error);
		}
	}



	// if( retryOrderConfig ){
	// 	/***
	// 		(okt 24)
	// 			[ ] werkt nu maar gaat eigenlijk niet zoals het hoort: 
	// 				- pagina is nog niet klaar met renderen en blijft deze nu in een loop aanroepen
	// 			[ ] voor nu gaat gebruiker gewoon terug naar deze pagina, zonder redirect naar Stripe checkout
	// 	*/
	// 	buyProduct( retryOrderConfig.priceIndex );
	// }



	/***************************************************************************
	
		HTML
	
	****************************************************************************/
	if (!course) {

		return <h2> Course not found </h2>;
	}



	return (

		<div>
			
			<div className		= "loading-indicator">
			<br></br>
			<ClipLoader
				color			= 'var(--tertiary-color)'
				loading			= {checkoutLoading} 
				size			= {50} 
			/>
			</div>



			{/***************************** 
				header 
			*****************************/}
			<div
				style 			= {{ 
									marginBottom:		'4rem'
								}}
			>
				<h1>
					{course.name}
				</h1>
			</div>



			{/***************************** 
				product container 
			*****************************/}
			<div
				className="product-container"
				
			>

				{/*****************************
					left container
				*****************************/}
				<div
					className			="product-description-container"
					
				>

					{/***************************** 
						preview video
					*****************************/}

					<div
						style				= {{
												display: 				'block',
												width: 					'80%',
												margin: 				'0',

												borderRadius: 			'20px',

												textAlign: 				'left'
											}}
					>

						<VideoPlayer 
							url							= { course.storeVideoUrl }
							light						= { course.storeImageUrl } // URL van de thumbnail
							hasRelativeDimensions 		= {true}
						/>
					</div>



					{/***************************** 
						Course info
					*****************************/}
					<div
						style={{

							width: 						'80%', 			// Zelfde breedte als de afbeelding
							margin: 					'0',
							color: 						'var(--tertiary-color)',
							textAlign: 					'left', 		// Tekst links uitlijnen
							paddingTop: 				'1rem',
						}}
					>
						{/*** De bron is gecontroleerd, dus dangerouslySetInnerHTML kan hier geen kwaad ***/}
						<p
								dangerouslySetInnerHTML={{
								__html: course.coursePageText
							}}
						>
						</p>

						

					</div>


					<div

						style={{
							width: 						'80%', 			// Zelfde breedte als de afbeelding
							margin: 					'0',
							paddingTop: 				'1rem',
							
							textAlign: 					'left',
							
							color: 						'var(--tertiary-color)'
						}}
					>

						<p>Course content:</p>

						{course.lessonTitleArray.map((title, index) => (
							<div
									key				= {index}
							>
								<p 
									style			= {{
															color: 						'var(--tertiary-color)',
															textAlign: 					'left',

															marginLeft:					'1rem'
														}}
								>
								{ title }
								</p>
							</div>
						))}
						
					</div>
				</div>



				{/******************************
					Order container
				*******************************/}
				<div
					className		= "product-order-container"
				>

					{/***************************** 
						Order panel
					*****************************/}
					<div 
						style			= {{ 
											display: 					'flex', 

											width: 						'70%',

											flexDirection:				'column',
											justifyContent:				'flex-start',
											alignItems: 				'center', 
										
											padding:					'2rem',
											
											textAlign: 					'center',              // Zorg dat tekst en inhoud binnen het panel gecentreerd zijn
									        margin: 					'0 auto',              // Zorg dat het panel zelf gecentreerd blijft in de container
											
											
											borderRadius: 				'20px',

											backgroundColor:			'var(--tertiary-color)',
											color:						'var(--primary-color)',
										}}
					>

						<h3>Complete order</h3>
		

						<div
							style			= {{ 
												display: 					'flex', 
												flexDirection:				'column',
												marginTop: 					'2rem',
											}}
							
						>

							{course.prices.map((price, index) => (
								<label 
									key				={index} 
									style			= {{ 
														marginRight: 	'2rem', 
														marginBottom: 	'1rem', 
														display: 		'block',

														color:			'var(--primary-color)',
													}}
								>
									<input
										type			="radio"
										name			="price"

										value			={index}
										checked			={selectedPriceIndex === index}
										onChange		={() => {
															
															setSelectedPriceIndex(index);
														}}

										style			={{
															marginRight: 	'12px',

															cursor: 		'pointer'
														}}
									/>
									{price}

								</label>

							))}
						
						</div>


						<div

							onClick			= {() => buyProduct()}

							style			= {{ 
												display: 				'flex', 
												alignItems: 			'center', 
												
												height: 				'40px',
												marginTop: 				'2rem',
												marginBottom: 			'1rem',

												padding: 				'1rem',

												color: 					'var(--secondary-color)',
												backgroundColor: 		'var(--primary-color)',
												borderRadius: 			'8px',

												cursor: 				'pointer',
											}}
						>
							<p
								style			= {{ fontWeight: 			'bold' }}
							>
								Buy course
							</p>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
}
